import { StyledLogo } from "../../App"
import Header from "../layouts/Header"
// import { IonIcons } from '@heroicons/react';
import NFT from "./NFT"


export default function Banner() {
    return (
      <div className="relative bg-white overflow-hidden" style={{ backgroundImage: 'url(/config/images/world_map.jpg)', backgroundSize: 'cover' }}>
        <Header />
        <div className="pt-16 pb-80 sm:pt-24 sm:pb-40 lg:pt-30 lg:pb-48 h-screen">
          <div className="flex-col max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
            <div className="sm:max-w-lg mt-auto bg-gray-700 bg-opacity-80 p-20 rounded-full">
            <p className="mt-4 text-4xl text-white font-sans italic" style={{ mixBlendMode: 'normal' }}>
            Embark on a Journey to the Enchanting Continent of...
              </p>
                <StyledLogo alt={"logo"} src={"/config/images/maho_white.png"} />
              <h1 className="text-5xl font font-extrabold tracking-tight text-gray-800 sm:text-6xl">
                
                {/* <IonIcons name="camera" /> */}
              </h1><br></br>
              <div><br></br>
                <center><NFT></NFT></center>
              </div>

              {/* <a href="#mint" className="inline-block text-center bg-gray-700 border border-transparent shadow-md rounded-md py-3 px-12 mt-8  text-lg font-medium text-white hover:bg-gray-900">Mint</a> */}
              </div>
          </div>
        </div>
      </div>
    )
  }
  