import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-hot-toast'
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";

import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
import { ethers } from "ethers";

import tokens from "../allowlist.json";


export const useNFT = () => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    "CONTRACT_ADDRESS": "",
    "SCAN_LINK": "https://etherscan.io/address/{contractAddress}",
    "NETWORK": {
      "NAME": "Ethereum",
      "SYMBOL": "ETH",
      "ID": 1
    },
    "NFT_NAME": "Maho",
    "SYMBOL": "Maho",
    "MAX_SUPPLY": 10000,
    "WEI_COST": 25000000000000000,
    "DISPLAY_COST": 0.025,
    "GAS_LIMIT": 100000,
    "MARKETPLACE": "Opensea",
    "MARKETPLACE_LINK": "https://opensea.io/collection/maho-nft",
    "SHOW_BACKGROUND": true,
    "WHITELIST_ENABLED": false
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    const toastId = toast.loading(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    blockchain.smartContract.methods
      .publicMint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong.");
        toast.error("Sorry, something went wrong.", { id: toastId });
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(`Your Mahi has been minted! Congrats!`);
        toast.success(`Your Mahi has been minted! Congrats!`, { id: toastId });
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 4) {
      newMintAmount = 4;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);


  const connectWallet = () => dispatch(connect())

  const maxSupply = CONFIG.MAX_SUPPLY;
  const supply = Number(data.totalSupply);
  const claimedSupply = supply;

  return {
    data, supply, maxSupply, claimedSupply,
    connectWallet, claimNFTs, mintAmount, decrementMintAmount, incrementMintAmount,
    account: blockchain.account
  }
};
