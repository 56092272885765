import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, PlusSmIcon, MinusSmIcon } from "@heroicons/react/outline";

import { Button } from '@nextui-org/react';
import { useNFT } from "../../hooks/useNFT";
import tokens from "../../allowlist.json";
import { ethers } from "ethers";

const product = {
    name: "Mori No Kodama",
    price: .025,
    imageSrc: "/config/images/dragonriding.jpg",
    imageAlt: "",
};

export default function MoriNoKodamaModal() {
    const [open, setOpen] = useState(false);

    return (
        <>
            <button
                onClick={() => setOpen(true)}
                className="inline-block mt-3 text-center bg-green-700 border border-transparent shadow-md rounded-full py-2 px-5 text-xl font-medium text-white hover:bg-green-900"
            >Backstory</button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-10 inset-0 overflow-y-auto"
                    onClose={setOpen}
                >
                    <div
                        className="flex min-h-screen text-center md:block md:px-2 lg:px-4"
                        style={{ fontSize: 0 }}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="hidden fixed inset-0 bg-grey-500 bg-opacity-75 transition-opacity md:block" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden md:inline-block md:align-middle md:h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
                                <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-lg sm:px-6 sm:pt-8 md:p-6 lg:p-8 rounded-xl">
                                    <button
                                        type="button"
                                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>

                                    <div className="sm:col-span-8 lg:col-span-7">
                                        <h2 className="text-4xl font-medium text-gray-900 sm:pr-12">
                                            {product.name}
                                        </h2>
                                        <hr className="border-t border-gray-300 my-8" />
                                        <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:items-center lg:gap-x-8">
                                            <div className="aspect-w-1 aspect-h-1 rounded-xl overflow-hidden sm:col-span-4 lg:col-span-12">
                                                <img
                                                    src="/config/images/enchanted_woodlands.jpg"
                                                    className="object-center object-cover rounded-xl w-full h-full"
                                                />
                                            </div>
                                        </div>

                                        <section
                                            aria-labelledby="information-heading"
                                            className="mt-1 bt-1"
                                        >
                                            <p className="font-bold text-lg text-gray-500 overflow-y-auto">
                                            The Mori no Kodama tribe dwells in the mystical Enchanted Woodlands, a lush and ancient forest that emanates an air of enchantment. Towering trees with sprawling branches create a dense canopy, filtering sunlight and casting a mesmerizing glow upon the forest floor. The air is perfumed with the scent of blossoms and the soft whispers of leaves, while gentle streams meander through the woodland, nourishing the flora and fauna that call this realm their home.<br></br>
                                                <br></br>
                                                The Mori no Kodama tribe possesses a deep attunement to nature, allowing them to commune with animals, manipulate plant life, and blend seamlessly into their surroundings. They serve as the guardians of the forest, understanding the delicate balance of life within the Enchanted Woodlands and embodying the harmony between all living beings.<br></br>
                                                <br></br>
                                                Within the Mori no Kodama tribe, their connection to nature is integral to their culture and values. They revere the sanctity of the forest, seeing themselves as stewards of its well-being. The Mori no Kodama people live in harmony with the flora and fauna, understanding that they are part of a greater ecosystem where each element relies upon one another.<br></br>
                                                <br></br>
                                                The tribe's strength lies in their ability to communicate with animals, fostering a profound understanding and cooperation with the creatures of the forest. They possess the power to manipulate plant life, encouraging growth, healing, and even commanding the elements of the woodland. Their ability to seamlessly blend into their surroundings allows them to move silently and undetected, granting them an advantage in both defense and offense.<br></br>
                                                <br></br>
                                                <center>
                                                    <div className="aspect-w-2 aspect-h-3 rounded-xl overflow-hidden sm:col-span-4 lg:col-span-5">
                                                        <img
                                                            src="/config/images/eldergreen_sakura.jpg"
                                                            className="object-center object-cover rounded-xl w-full h-full"
                                                        />
                                                    </div>
                                                </center>
                                                <br></br>
                                                Power structures within the Mori no Kodama tribe are based on a deep respect for wisdom, empathy, and the ability to communicate with nature. The leaders of the tribe, known as Eldergreens, rise to prominence through their exceptional attunement to nature, their understanding of the forest's intricate web of life, and their ability to guide and protect their people.<br></br>
                                                <br></br>
                                                The ascent to leadership among the Mori no Kodama tribe is marked by a profound connection to nature and the acquisition of wisdom through experience. Prospective Eldergreens undergo a journey of spiritual growth, learning from the forest itself and the guidance of those who came before them. Those who demonstrate exceptional attunement to nature, display wisdom in understanding the delicate balance of the Enchanted Woodlands, and earn the respect of their tribe members ascend to become the Eldergreens, guiding the Mori no Kodama tribe with their deep-rooted wisdom.<br></br>
                                                <br></br>
                                                The current leader of the Mori no Kodama tribe, the Eldergreen Sakura, rose to power through her profound connection to nature and her unwavering commitment to the well-being of the Enchanted Woodlands. Sakura displayed an innate ability to communicate with animals and an exceptional understanding of the forest's interconnectedness. Through her wisdom, empathy, and her dedication to the preservation of the woodland, Sakura ascended to the position of Eldergreen, leading the Mori no Kodama tribe with a gentle and nurturing hand.<br></br>
                                                <br></br>
                                                As a tribe, the Mori no Kodama people embody the harmony and interconnectedness found within nature. They strive to protect and preserve the Enchanted Woodlands, while fostering understanding and respect for all living beings. The Mori no Kodama tribe stands as the guardians of the forest, a reminder of the delicate balance between humanity and the natural world in the realm of Maho.<br></br>
                                                <br></br>

                                                <center>
                                                    <div className="aspect-w-2 aspect-h-3 rounded-xl overflow-hidden sm:col-span-4 lg:col-span-5">
                                                        <img
                                                            src="/config/images/mori_owl.jpg"
                                                            className="object-center object-cover rounded-xl w-full h-full"
                                                        />
                                                    </div>
                                                </center>
                                            </p>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
