import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, PlusSmIcon, MinusSmIcon } from "@heroicons/react/outline";

import { Button } from '@nextui-org/react';
import { useNFT } from "../../hooks/useNFT";
import tokens from "../../allowlist.json";
import { ethers } from "ethers";

const product = {
    name: "Kaji No Netsu",
    price: .025,
    imageSrc: "/config/images/dragonriding.jpg",
    imageAlt: "",
};

export default function KajiNoNetsuModal() {
    const [open, setOpen] = useState(false);

    return (
        <>
            <button
                onClick={() => setOpen(true)}
                className="inline-block mt-3 text-center bg-red-700 border border-transparent shadow-md rounded-full py-2 px-5 text-xl font-medium text-white hover:bg-red-900"
            >Backstory</button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-10 inset-0 overflow-y-auto"
                    onClose={setOpen}
                >
                    <div
                        className="flex min-h-screen text-center md:block md:px-2 lg:px-4"
                        style={{ fontSize: 0 }}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="hidden fixed inset-0 bg-grey-500 bg-opacity-75 transition-opacity md:block" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden md:inline-block md:align-middle md:h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
                                <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-lg sm:px-6 sm:pt-8 md:p-6 lg:p-8 rounded-xl">
                                    <button
                                        type="button"
                                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>

                                    <div className="sm:col-span-8 lg:col-span-7">
                                        <h2 className="text-4xl font-medium text-gray-900 sm:pr-12">
                                            {product.name}
                                        </h2>
                                        <hr className="border-t border-gray-300 my-8" />
                                        <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:items-center lg:gap-x-8">
                                            <div className="aspect-w-1 aspect-h-1 rounded-xl overflow-hidden sm:col-span-4 lg:col-span-12">
                                                <img
                                                    src="/config/images/volcanic_caldera.jpg"
                                                    className="object-center object-cover rounded-xl w-full h-full"
                                                />
                                            </div>
                                        </div>

                                        <section
                                            aria-labelledby="information-heading"
                                            className="mt-1 bt-1"
                                        >
                                            <p className="font-bold text-lg text-gray-500 overflow-y-auto">
                                            The Kaji no Netsu tribe makes their home in the formidable Ember Citadel, a stronghold nestled amidst a vast volcanic caldera. Molten rivers of lava cascade down the volcanic slopes, casting an eerie red glow across the landscape. The air crackles with intense heat, and the ground pulses with the raw energy of the earth's core. The Ember Citadel is a testament to the fiery power harnessed by the Kaji no Netsu tribe.<br></br>
                                                <br></br>
                                                The Kaji no Netsu tribe possesses a profound attunement to fire, allowing them to command and manipulate flames with unparalleled precision. They draw strength from the intense energy of their element, embracing the fervor and passion it represents. The Kaji no Netsu people excel in generating fire, withstanding scorching temperatures, and forging objects through their fiery touch.<br></br>
                                                <br></br>
                                                Within the Kaji no Netsu tribe, the strength of their bond with fire shapes their collective identity and drives their ambitions. They believe that fire represents not only destruction but also creation and renewal. They harness its power to forge weapons, craft intricate artwork, and ignite the flames of their passions.<br></br>
                                                <br></br>
                                                The tribe's strength lies in their ability to channel the primal force of fire, unleashing devastating attacks and overwhelming opponents with intense heat. However, their reliance on fire can also be a vulnerability, as they are susceptible to the extinguishing effects of water and dampened environments that diminish their powers<br></br>
                                                <br></br>
                                                <center>
                                                    <div className="aspect-w-2 aspect-h-3 rounded-xl overflow-hidden sm:col-span-4 lg:col-span-5">
                                                        <img
                                                            src="/config/images/emberlord_takumi_2.jpg"
                                                            className="object-center object-cover rounded-xl w-full h-full"
                                                        />
                                                    </div>
                                                </center>
                                                <br></br>
                                                Power structures within the Kaji no Netsu tribe are built upon a respect for strength, strategic thinking, and the ability to command fire with finesse. The leaders of the tribe, known as Emberlords, rise to prominence based on their exceptional control over flames, their tactical acumen, and their profound understanding of the intense forces they wield.<br></br>
                                                <br></br>
                                                The ascent to leadership among the Kaji no Netsu tribe is a testament to their mastery of fire and their capacity to inspire and lead others. Prospective Emberlords undergo rigorous training and trials, honing their abilities to manipulate fire and demonstrating their skill in strategic thinking. Those who prove themselves worthy rise to become the guiding figures of the tribe, steering its goals and strategies.<br></br>
                                                <br></br>
                                                The current leader of the Kaji no Netsu tribe, the Emberlord Takumi, rose to power through a combination of natural talent and rigorous training. Takumi displayed exceptional control over fire from a young age, showcasing a deep understanding of its raw power and the responsibility that comes with wielding it. Through their tactical brilliance, unwavering dedication, and the respect they commanded among their peers, Takumi ascended to the position of Emberlord, leading the Kaji no Netsu tribe with an unyielding passion.<br></br>
                                                <br></br>
                                                As a tribe, the Kaji no Netsu people embrace the transformative and dynamic nature of fire. They seek to forge their own paths and ignite the flames of passion within themselves and others. The Kaji no Netsu tribe stands as a testament to the immense power and boundless potential found within the fiery depths, constantly striving to master their attunement and leaving their mark upon the world of Maho.<br></br>
                                                <br></br>

                                                <center>
                                                    <div className="aspect-w-2 aspect-h-3 rounded-xl overflow-hidden sm:col-span-4 lg:col-span-5">
                                                        <img
                                                            src="/config/images/KajiNoNetsuCitizen_2.jpg"
                                                            className="object-center object-cover rounded-xl w-full h-full"
                                                        />
                                                    </div>
                                                </center>
                                            </p>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
