import { SparklesIcon } from '@heroicons/react/outline'
import { BookOpenIcon, MoonIcon, EyeIcon, LightBulbIcon, ViewGridIcon, TerminalIcon } from '@heroicons/react/outline'
import HikarinoModal from '../organisms/HikarinoModal'
import KajiNoNetsuModal from '../organisms/KajiNoNetsuModal'
import KurayamiModal from '../organisms/KurayamiModal'
import MizumiModal from '../organisms/MizumiModal'
import KaminariModal from '../organisms/KaminariModal'
import MoriNoKodamaModal from '../organisms/MoriNoKodama'
import CommercialRightsModal from '../organisms/CommercialRightsModal'
import TermsAndConditionsModal from '../organisms/TermsAndConditionsModal'


export default function Body() {
  return (
    <>
      <div className="relative bg-white pt-16 pb-32 overflow-hidden">
        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div className="mt-6">
                  <h2 className="text-6xl font-extrabold tracking-tight text-gray-600">
                    The Basics
                    <hr className="border-t border-gray-300 my-8" />
                  </h2>
                  <p className="mt-4 text-lg text-gray-500 whitespace-normal">
                    Delve deep into the enchanting realm of Maho, immerse yourself in a world steeped in magic, unity, and boundless wonders. Maho, the radiant supercontinent, is home to diverse tribes whose attunements connect them to the elemental forces of nature. Uncover the mysteries of Maho, witness the Grand Tournament of Attunements, and get ready to unleash the magic within.
                  </p>
                  <hr className="border-t border-gray-300 my-8" />

                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="px-4 md:pl-4 lg:-mr-48 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full rounded-full shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={"/config/images/world_tournament_gates.jpg"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-24">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <div>
                </div>
                <div className="mt-6">
                  <h2 className="text-6xl font-extrabold tracking-tight text-gray-600">
                    Meet the Tribes
                    <hr className="border-t border-gray-300 my-8" />
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    From the luminous peaks of the Hikarino tribe to the shadowy depths of the Kurayami, each tribe possesses unique abilities and a deep connection to their environment, called an Attunement. Set foot upon the Azure Archipelago of the Mizumi, feel the fiery power within the Volcanic Caldera of the Kaji No Netsu, and behold the awe-inspiring Stormbreaker Cliffs of the Kaminari. Finally, wander the ancient woods of the Mori No Kodama and listen to whispers of ancient wisdom as they echo through the enchanted foliage.
                  </p>
                  <hr className="border-t border-gray-300 my-8" />

                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="px-4 lg:-ml-48 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full rounded-full shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={"/config/images/KajiNoNetsuCitizen.jpg"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />


        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div className="mt-6">
                  <h2 className="text-6xl font-extrabold tracking-tight text-yellow-700">
                    Hikarino
                    <hr className="border-t border-yellow-300 my-8" />
                  </h2>
                  <p className="mt-4 text-lg text-gray-500 whitespace-normal">
                    Etymology: Light of the Sun<br></br>
                    Home: Radiant Peaks<br></br>
                    Attunement: Light<br></br>
                    Leader: Luminarch Eiko<br></br>
                    Rivals: Kurayami<br></br><br></br>
                    <i>"Illuminate the Path of Harmony, for the brilliance of light brings peace and enlightenment."</i><br />
                    <br />
                  </p>
                  <HikarinoModal></HikarinoModal>
                  <hr className="border-t border-yellow-300 my-8" />

                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="px-4 lg:-mr-48 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full rounded-full shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={"/config/images/luminarch.jpg"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-24">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <div>
                </div>
                <div className="mt-6">
                  <h2 className="text-6xl font-extrabold tracking-tight text-gray-600">
                    Kurayami
                    <hr className="border-t border-gray-700 my-8" />
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Etymology: Children of Darkness<br></br>
                    Home: Umbral Enclave<br></br>
                    Attunement: Dark<br></br>
                    Leader: Shadowmaster Akira<br></br>
                    Rivals: Hikarino<br></br><br></br>
                    <i>"Embrace the Shadows, for within darkness lies strength and revelation."</i><br />
                    <br />
                    <KurayamiModal></KurayamiModal>
                    <hr className="border-t border-gray-700 my-8" />
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="px-4 lg:-ml-48 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full rounded-full shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={"/config/images/shadowmaster_akira_1.jpg"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />

        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div className="mt-6">
                  <h2 className="text-6xl font-extrabold tracking-tight text-blue-900">
                    Mizumi
                    <hr className="border-t border-blue-700 my-8" />
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Etymology: Water's Embrace<br></br>
                    Home: Azure Archipelago<br></br>
                    Attunement: Water<br></br>
                    Leader: Tidecaller Miyuki<br></br>
                    Rivals: Kaji No Netsu<br></br><br></br>
                    <i>"Flow with the Tides, where serenity and resilience unite, the waters guide our journey."</i><br />
                    <br />
                    <MizumiModal></MizumiModal>
                    <hr className="border-t border-blue-700 my-8" />
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="px-4 lg:-mr-48 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full rounded-full shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={"/config/images/tidecaller_miyuki_1.jpg"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>


        <div className="mt-24">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <div>
                </div>
                <div className="mt-6">
                  <h2 className="text-6xl font-extrabold tracking-tight text-red-900">
                    Kaji No Netsu
                    <hr className="border-t border-red-700 my-8" />
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Etymology: Flames of Passion<br></br>
                    Home: Volcanic Caldera<br></br>
                    Attunement: Fire<br></br>
                    Leader: Emberlord Takumi<br></br>
                    Rivals: Mizumi<br></br><br></br>
                    <i>"Unleash the Inferno Within, for our spirits burn bright with the power to ignite change and reshape the world."</i><br />
                    <br />
                    <KajiNoNetsuModal></KajiNoNetsuModal>
                    <hr className="border-t border-red-700 my-8" />
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="px-4 lg:-ml-48 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full rounded-full shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={"/config/images/emberlord_takumi.jpg"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />

        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div className="mt-6">
                  <h2 className="text-6xl font-extrabold tracking-tight text-indigo-900">
                    Kaminari
                    <hr className="border-t border-indigo-300 my-8" />
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Etymology: Thunder's Roar<br></br>
                    Home: Stormbreaker Cliffs<br></br>
                    Attunement: Lightning / Wind<br></br>
                    Leader: Storm King Reiko<br></br>
                    Rivals: Mori No Kodama<br></br><br></br>
                    <i>"Only in the tempest's embrace, can we harness the power to reshape this world and strike our foes with unstoppable force."</i><br />
                    <br />
                    <KaminariModal></KaminariModal>
                    <hr className="border-t border-indigo-300 my-8" />
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="px-4 lg:-mr-48 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full rounded-full shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={"/config/images/storm_king_reiko.jpg"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-24">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <div>
                </div>
                <div className="mt-6">
                  <h2 className="text-6xl font-extrabold tracking-tight text-green-900">
                    Mori No Kodama
                    <hr className="border-t border-green-700 my-8" />
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Etymology: Guardian's of the Forest<br></br>
                    Home: Enchanted Woodlands<br></br>
                    Attunement: Nature<br></br>
                    Leader: Eldergreen Sakura<br></br>
                    Rivals: The Kaminari<br></br><br></br>
                    <i>"Nurture the Roots of Wisdom, for in the whispering woods, our spirits intertwine with nature's secrets, guiding us towards harmony and renewal."</i><br />
                    <br />
                    <MoriNoKodamaModal></MoriNoKodamaModal>
                    <hr className="border-t border-green-700 my-8" />
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="px-4 lg:-ml-48 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full rounded-full shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={"/config/images/eldergreen_sakura_2.jpg"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-1 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div className="mt-6">
                  <h2 className="text-6xl font-extrabold tracking-tight text-gray-900">
                    FAQ
                    <hr className="border-t border-gray-300 my-8" />
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Q: What is a Maho NFT a.k.a. a Mahi?<br></br>
                    A: A Mahi is a single instance of a non fungible token belonging to the 10,000 unit Maho NFT collection. The token is linked to a digital illustration that confers the owner a commercial licensing agreement as applicable by the Maho NFT Terms and Conditions.<br></br>
                    <hr className="border-t border-gray-300 my-8" />
                    Q: What benefits do I get by owning a Mahi?<br></br>
                    A: Mahi NFT owners own full and exclusive commercial rights to the art work of their individual NFT. In addition to enjoying the art, they are able to build commercial products, both digital and physical, with their NFT's IP.
                    <br />
                    <hr className="border-t border-gray-300 my-8" />
                    Q: What are the terms and conditions of purchasing a Mahi?<br></br>
                    A: The terms and conditions of purchasing a Mahi can be found here.<br></br>
                    <TermsAndConditionsModal></TermsAndConditionsModal>
                    <hr className="border-t border-gray-300 my-8" />
                    Q: Do I have Commercial rights to my Mahi?<br></br>
                    A: Absolutely, yes. Maho NFT holders have full commercial rights as dictated by the Maho NFT Commercial Licensing Agreement found below.
                    <br />
                    <CommercialRightsModal></CommercialRightsModal>
                    <hr className="border-t border-gray-300 my-8" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}