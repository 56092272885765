import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, PlusSmIcon, MinusSmIcon } from "@heroicons/react/outline";

import { Button } from '@nextui-org/react';
import { useNFT } from "../../hooks/useNFT";
import tokens from "../../allowlist.json";
import { ethers } from "ethers";

const product = {
    name: "Commercial Licensing Agreement",
    imageAlt: "",
};

export default function CommercialRightsModal() {
    const [open, setOpen] = useState(false);

    return (
        <>
            <button
                onClick={() => setOpen(true)}
                className="inline-block mt-3 text-center bg-gray-700 border border-transparent shadow-md rounded-full py-2 px-5 text-xl font-medium text-white hover:bg-gray-900"
            >Commercial Licensing Agreement</button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-10 inset-0 overflow-y-auto"
                    onClose={setOpen}
                >
                    <div
                        className="flex min-h-screen text-center md:block md:px-2 lg:px-4"
                        style={{ fontSize: 0 }}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="hidden fixed inset-0 bg-grey-500 bg-opacity-75 transition-opacity md:block" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden md:inline-block md:align-middle md:h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
                                <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-lg sm:px-6 sm:pt-8 md:p-6 lg:p-8 rounded-xl">
                                    <button
                                        type="button"
                                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>

                                    <div className="sm:col-span-8 lg:col-span-7">
                                        <h2 className="text-4xl font-medium text-gray-900 sm:pr-12">
                                            {product.name}
                                        </h2>
                                        <hr className="border-t border-gray-300 my-8" />
                                        <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:items-center lg:gap-x-8">
                                        </div>

                                        <section
                                            aria-labelledby="information-heading"
                                            className="mt-1 bt-1"
                                        >
                                            <p className="font-bold text-lg text-gray-500 overflow-y-auto">
                                            LICENSE FOR COMMERCIAL USE OF THE ART: For so long as you continue to Own the applicable NFT and fully comply with these Terms & Conditions, the Creator grants you a limited, worldwide, non-exclusive, non-transferable license to use, copy, and display the Art for your Purchased NFT for the purpose of commercializing your own physical and digital merchandise that includes, contains, or consists of the Art for your Purchased NFT. </p>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
