import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, PlusSmIcon, MinusSmIcon } from "@heroicons/react/outline";

import { Button } from '@nextui-org/react';
import { useNFT } from "../../hooks/useNFT";
import tokens from "../../allowlist.json";
import { ethers } from "ethers";

const product = {
    name: "The Mizumi",
    price: .025,
    imageSrc: "/config/images/dragonriding.jpg",
    imageAlt: "",
};

export default function MizumiModal() {
    const [open, setOpen] = useState(false);

    return (
        <>
            <button
                onClick={() => setOpen(true)}
                className="inline-block mt-3 text-center bg-blue-700 border border-transparent shadow-md rounded-full py-2 px-5 text-xl font-medium text-white hover:bg-blue-900"
            >Backstory</button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-10 inset-0 overflow-y-auto"
                    onClose={setOpen}
                >
                    <div
                        className="flex min-h-screen text-center md:block md:px-2 lg:px-4"
                        style={{ fontSize: 0 }}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="hidden fixed inset-0 bg-grey-500 bg-opacity-75 transition-opacity md:block" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden md:inline-block md:align-middle md:h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
                                <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-lg sm:px-6 sm:pt-8 md:p-6 lg:p-8 rounded-xl">
                                    <button
                                        type="button"
                                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>

                                    <div className="sm:col-span-8 lg:col-span-7">
                                        <h2 className="text-4xl font-medium text-gray-900 sm:pr-12">
                                            {product.name}
                                        </h2>
                                        <hr className="border-t border-gray-300 my-8" />
                                        <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:items-center lg:gap-x-8">
                                            <div className="aspect-w-1 aspect-h-1 rounded-xl overflow-hidden sm:col-span-4 lg:col-span-12">
                                                <img
                                                    src="/config/images/azure_archipelago.jpg"
                                                    className="object-center object-cover rounded-xl w-full h-full"
                                                />
                                            </div>
                                        </div>

                                        <section
                                            aria-labelledby="information-heading"
                                            className="mt-1 bt-1"
                                        >
                                            <p className="font-bold text-lg text-gray-500 overflow-y-auto">
                                            The Mizumi tribe, bearers of the attunement to water, has a captivating history woven through the vibrant tapestry of Maho. As guardians of the aquatic realms, the Mizumi people have encountered unique struggles and nurtured distinct goals within the world they inhabit.<br></br>
                                                <br></br>
                                                The Mizumi tribe's primary struggle lies in their ongoing rivalry with the Kaji No Netsu, a tribe attuned to fire and hailing from the volcanic caldera. The clash between water and fire has sparked both conflict and occasional cooperation, as the Mizumi and Kaji No Netsu tribes vie for control over the delicate balance of ecosystems that connect their realms. This rivalry reflects the eternal dance between water and fire, and the Mizumi tribe's steadfast goal is to ensure the preservation and harmony of the watery realms they hold dear keeping at bay the volatility of the fire their rivals hold most dear.<br></br>
                                                <br></br>
                                                The Mizumi people possess an innate strength in their affinity for water, granting them the ability to manipulate currents, breathe underwater, and heal using the rejuvenating properties of the sea. Their bond with aquatic life and the healing properties of water forms the foundation of their attunement, allowing them to protect their habitats and aid their fellow tribespeople. However, their dependency on water for their powers makes them vulnerable in arid regions, and their strength is diminished when separated from their watery domains.<br></br>
                                                <br></br>
                                                Within the Mizumi tribe, power structures are built upon a deep respect for wisdom, adaptability, and a connection to the life-giving forces of water. The leaders, known as Tidecallers, rise to prominence through their exceptional attunement to water, their ability to navigate the complex underwater ecosystems, and their leadership qualities.<br></br>
                                                <br></br>
                                                <center>
                                                    <div className="aspect-w-2 aspect-h-3 rounded-xl overflow-hidden sm:col-span-4 lg:col-span-5">
                                                        <img
                                                            src="/config/images/tidecaller_miyuki_2.jpg"
                                                            className="object-center object-cover rounded-xl w-full h-full"
                                                        />
                                                    </div>
                                                </center>
                                                <br></br>
                                                The ascent to leadership among the Mizumi tribe is guided by a collective decision-making process. Prospective Tidecallers undergo rigorous training and are tested on their knowledge of aquatic ecosystems, their understanding of water's life-giving properties, and their capacity to make sound and compassionate decisions. The tribe as a whole recognizes the importance of communal wisdom and ensures that their leaders embody these values.<br></br>
                                                <br></br>
                                                The current leader of the Mizumi tribe, the Tidecaller Miyuki, rose to power through her remarkable mastery of water and her deep empathy for aquatic life. Miyuki displayed an extraordinary ability to communicate with sea creatures from a young age, developing a profound understanding of the delicate balance within their watery realms. Her humility, compassion, and unwavering dedication to the preservation of aquatic habitats earned her the respect and trust of her fellow tribe members, leading to her ascendancy as the Tidecaller.<br></br>
                                                <br></br>
                                                As a tribe, the Mizumi people strive to safeguard the delicate ecosystems of rivers, lakes, and oceans throughout Maho. Their deep connection to water fuels their desire to protect and restore these habitats, and they often seek alliances with other tribes to promote the sustainable use and preservation of water resources. The Mizumi tribe embraces the responsibility of being stewards of water, fostering understanding and cooperation among the tribes of Maho in the quest for a harmonious coexistence.<br></br>
                                                <br></br>
                                                In the realm of Maho, the Mizumi tribe stands as a testament to the importance of balance, adaptability, and the preservation of the precious resource that is water. Their struggles and goals revolve around maintaining the integrity of aquatic realms, forging alliances, and guiding the tribes of Maho toward a future that cherishes the interconnectedness of all life.<br></br>
                                                <br></br>

                                                <center>
                                                    <div className="aspect-w-2 aspect-h-3 rounded-xl overflow-hidden sm:col-span-4 lg:col-span-5">
                                                        <img
                                                            src="/config/images/mizumi_dragon.jpg"
                                                            className="object-center object-cover rounded-xl w-full h-full"
                                                        />
                                                    </div>
                                                </center>
                                            </p>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
