import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, PlusSmIcon, MinusSmIcon } from "@heroicons/react/outline";

import { Button } from '@nextui-org/react';
import { useNFT } from "../../hooks/useNFT";
import tokens from "../../allowlist.json";
import { ethers } from "ethers";

const product = {
    name: "The Kurayami",
    price: .025,
    imageSrc: "/config/images/dragonriding.jpg",
    imageAlt: "",
};

export default function KurayamiModal() {
    const [open, setOpen] = useState(false);

    return (
        <>
            <button
                onClick={() => setOpen(true)}
                className="inline-block mt-3 text-center bg-gray-700 border border-transparent shadow-md rounded-full py-2 px-5 text-xl font-medium text-white hover:bg-gray-900"
            >Backstory</button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-10 inset-0 overflow-y-auto"
                    onClose={setOpen}
                >
                    <div
                        className="flex min-h-screen text-center md:block md:px-2 lg:px-4"
                        style={{ fontSize: 0 }}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="hidden fixed inset-0 bg-grey-500 bg-opacity-75 transition-opacity md:block" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden md:inline-block md:align-middle md:h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
                                <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-lg sm:px-6 sm:pt-8 md:p-6 lg:p-8 rounded-xl">
                                    <button
                                        type="button"
                                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>

                                    <div className="sm:col-span-8 lg:col-span-7">
                                        <h2 className="text-4xl font-medium text-gray-900 sm:pr-12">
                                            {product.name}
                                        </h2>
                                        <hr className="border-t border-gray-300 my-8" />
                                        <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:items-center lg:gap-x-8">
                                            <div className="aspect-w-1 aspect-h-1 rounded-xl overflow-hidden sm:col-span-4 lg:col-span-12">
                                                <img
                                                    src="/config/images/umbral_enclave.jpg"
                                                    className="object-center object-cover rounded-xl w-full h-full"
                                                />
                                            </div>
                                        </div>

                                        <section
                                            aria-labelledby="information-heading"
                                            className="mt-1 bt-1"
                                        >
                                            <p className="font-bold text-lg text-gray-500 overflow-y-auto">
                                                The Kurayami tribe finds solace within the Umbral Enclave, a mysterious and secluded realm hidden deep within the heart of a vast, dense forest. The canopy overhead is thick and interwoven, casting the enclave into perpetual twilight. Shadows dance among ancient trees, and an eerie hush hangs in the air, giving the enclave an aura of enigmatic power.<br></br>
                                                <br></br>
                                                The Kurayami tribe possesses a unique attunement to darkness, allowing them to manipulate shadows and tap into the hidden depths of the night. They draw strength from the obscure and possess an uncanny ability to navigate in the dimmest of light. The Kurayami people embrace the profound mysteries of the night, finding beauty and solace within its depths.<br></br>
                                                <br></br>
                                                The tribe's existence is marked by a constant struggle against the rival Hikarino tribe, bearers of the attunement to light. The Kurayami people perceive the Hikarino tribe as misguided, believing that true enlightenment lies not in the radiance of light but in the profound depths of darkness. This rivalry, born from opposing philosophies and conflicting worldviews, fuels their determination to prove the strength and power of darkness.<br></br>
                                                <br></br>
                                                While the Kurayami tribe derives its strength from the shadows, they are aware of the limitations and vulnerabilities it brings. Their powers are at their apex during the night, but they find themselves weakened when confronted with an overwhelming presence of light. To counter this, the Kurayami people have honed their skills in stealth and subterfuge, becoming masterful manipulators of shadows and experts in covert operations.<br></br>
                                                <br></br>
                                                <center>
                                                    <div className="aspect-w-2 aspect-h-3 rounded-xl overflow-hidden sm:col-span-4 lg:col-span-5">
                                                        <img
                                                            src="/config/images/shadowmaster_akira_2.jpg"
                                                            className="object-center object-cover rounded-xl w-full h-full"
                                                        />
                                                    </div>
                                                </center>
                                                <br></br>
                                                Within the Kurayami tribe, power structures are built upon secrecy and intuition. The leaders, known as the Shadowmasters, rise to prominence through a combination of raw power, cunning, and an innate understanding of the enigmatic forces that shape their attunement. Shadowmasters are chosen based on their ability to harness darkness, their skill in navigating the intricacies of the Umbra Enclave, and their talent for outmaneuvering opponents<br></br>
                                                <br></br>
                                                The current leader of the Kurayami tribe, the Shadowmaster Akira, ascended to power through a tumultuous journey of self-discovery. Akira demonstrated exceptional control over shadows from an early age, often disappearing into the depths of darkness to gather insights and secrets. Through their cunning and ability to manipulate situations to their advantage, Akira gained the trust and respect of the Kurayami tribe, ultimately becoming the Shadowmaster and guiding the tribe with an unwavering conviction in the power of darkness.<br></br>
                                                <br></br>
                                                The Kurayami tribe, while viewed as enigmatic and mysterious by others, possesses a deep sense of loyalty and unity among its members. They believe in embracing the unknown and uncovering the hidden truths that lie in the depths of darkness. In the realm of Maho, the Kurayami tribe serves as a constant reminder that there is power to be found even in the most obscure and misunderstood aspects of life.<br></br>
                                                <br></br>
                                                <center>
                                                    <div className="aspect-w-2 aspect-h-3 rounded-xl overflow-hidden sm:col-span-4 lg:col-span-5">
                                                        <img
                                                            src="/config/images/umbral_enclave_panther.jpg"
                                                            className="object-center object-cover rounded-xl w-full h-full"
                                                        />
                                                    </div>
                                                </center>
                                            </p>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
