import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, PlusSmIcon, MinusSmIcon } from "@heroicons/react/outline";

import { Button } from '@nextui-org/react';
import { useNFT } from "../../hooks/useNFT";
import tokens from "../../allowlist.json";
import { ethers } from "ethers";

const product = {
  name: "Maho",
  price: .025,
  imageSrc: "/config/images/dragonriding.jpg",
  imageAlt: "",
};



export default function NFT() {
  const [open, setOpen] = useState(false);
  const {
    supply, maxSupply, claimedSupply, account, mintAmount,
    connectWallet, claimNFTs, incrementMintAmount, decrementMintAmount
  } = useNFT();

  return (
    <>
      <button
        //onClick={() => setOpen(true)}
        disabled={true}
        className="inline-block text-center bg-blue-700 border border-transparent shadow-md rounded-full py-5 px-8 text-2xl font-medium text-white hover:bg-blue-900"
      >Coming Soon.</button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div
            className="flex min-h-screen text-center md:block md:px-2 lg:px-4"
            style={{ fontSize: 0 }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="hidden fixed inset-0 bg-grey-500 bg-opacity-75 transition-opacity md:block" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden md:inline-block md:align-middle md:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
                <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-lg sm:px-6 sm:pt-8 md:p-6 lg:p-8 rounded-xl">
                  <button
                    type="button"
                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:items-center lg:gap-x-8">
                    <div className="aspect-w-2 aspect-h-3 rounded-lg bg-blue-100 overflow-hidden sm:col-span-4 lg:col-span-5">
                      <img
                        src={product.imageSrc}
                        alt={product.imageAlt}
                        className="object-center object-cover rounded-xl"
                      />
                    </div>
                    <div className="sm:col-span-8 lg:col-span-7">
                      <h2 className="text-4xl font-medium text-gray-900 sm:pr-12">
                        {product.name}

                      </h2>

                      <section
                        aria-labelledby="information-heading"
                        className="mt-1"
                      >
                        {!account && (
                          <p className="font-bold text-md text-gray-500">
                            {claimedSupply}/{maxSupply}<br></br><br></br>

                          </p>
                        )}

                        {account && (
                          <p className="font-bold text-md text-gray-500">
                            {claimedSupply}/{maxSupply}<br></br><br></br>

                          </p>
                        )}

                        {!account && (
                          <p className="font-bold text-lg text-gray-500">
                            Ready to unleash the magic within?<br></br><br></br>
                          </p>
                        )}

                        {account && (
                          <p className="font-bold text-lg text-gray-500">

                            Ready to unleash the magic within?<br></br><br></br>
                          </p>
                        )}

                        {!account && (
                          <p className="font-bold text-sm text-gray-500">
                            By connecting your wallet to this website and minting, you are confirming you have read and agreed to our Terms and Conditions<br></br>
                          </p>
                        )}

                        {account && (
                          <p className="font-bold text-sm text-gray-500">
                            By connecting your wallet to this website and minting, you are confirming you have read and agreed to our Terms and Conditions<br></br>
                          </p>
                        )}


                        {supply > 0 && (
                          <>
                            <br></br><p className="font-bold text-xl text-gray-800">Total: {(product.price * mintAmount).toFixed(3)} ETH + gas</p>
                          </>
                        )}
                      </section>

                      {supply > 0 && (
                        <div className="flex justify-center mt-6">
                          <button onClick={decrementMintAmount} className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-blue-700 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                            <MinusSmIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                          <p className="text-4xl mx-4">{mintAmount}</p>
                          <button onClick={incrementMintAmount} className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-blue-700 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                            <PlusSmIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      )}

                      <section aria-labelledby="options-heading" className="mt-8">
                        <button
                          onClick={account ? claimNFTs : connectWallet}
                          className="mt-auto w-full bg-blue-700 border border-transparent rounded-full py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          {account ? 'Mint!' : 'Connect Wallet'}
                        </button>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
