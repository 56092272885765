import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, PlusSmIcon, MinusSmIcon } from "@heroicons/react/outline";

import { Button } from '@nextui-org/react';
import { useNFT } from "../../hooks/useNFT";
import tokens from "../../allowlist.json";
import { ethers } from "ethers";

const product = {
    name: "The Hikarino",
    price: .025,
    imageSrc: "/config/images/dragonriding.jpg",
    imageAlt: "",
};

export default function HikarinoModal() {
    const [open, setOpen] = useState(false);

    return (
        <>
            <button
                onClick={() => setOpen(true)}
                className="inline-block mt-3 text-center bg-yellow-500 border border-transparent shadow-md rounded-full py-2 px-5 text-xl font-medium text-white hover:bg-yellow-600"
            >Backstory</button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-10 inset-0 overflow-y-auto"
                    onClose={setOpen}
                >
                    <div
                        className="flex min-h-screen text-center md:block md:px-2 lg:px-4"
                        style={{ fontSize: 0 }}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="hidden fixed inset-0 bg-grey-500 bg-opacity-75 transition-opacity md:block" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden md:inline-block md:align-middle md:h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
                                <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-lg sm:px-6 sm:pt-8 md:p-6 lg:p-8 rounded-xl">
                                    <button
                                        type="button"
                                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>

                                    <div className="sm:col-span-8 lg:col-span-7">
                                        <h2 className="text-4xl font-medium text-gray-900 sm:pr-12">
                                            {product.name}
                                        </h2>
                                        <hr className="border-t border-gray-300 my-8" />
                                        <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:items-center lg:gap-x-8">
                                            <div className="aspect-w-1 aspect-h-1 rounded-xl overflow-hidden sm:col-span-4 lg:col-span-12">
                                                <img
                                                    src="/config/images/radiant_plateau_landscape.jpg"
                                                    className="object-center object-cover rounded-xl w-full h-full"
                                                />
                                            </div>
                                        </div>

                                        <section
                                            aria-labelledby="information-heading"
                                            className="mt-1 bt-1"
                                        >
                                            <p className="font-bold text-lg text-gray-500 overflow-y-auto">
                                                The Hikarino tribe, bearers of the attunement to light, have a rich and complex history within the world of Maho. In their pursuit of harmony and enlightenment, the Hikarino people have faced numerous challenges, rivalries, and internal struggles, all while striving to uphold their core values and aspirations.<br></br>
                                                <br></br>
                                                The Hikarino tribe's primary struggle has been against the shadow-wielding Kurayami tribe, who are attuned to darkness. This rivalry between light and darkness has persisted for generations, representing a delicate balance of opposing forces. The Kurayami tribe, driven by a different philosophy and worldview, often clashes with the Hikarino tribe, seeking to undermine their peaceful existence and harness the power of darkness for their own gain.<br></br>
                                                <br></br>
                                                The Hikarino people possess an innate strength in their ability to illuminate the world around them and heal both physical and spiritual wounds. However, this attunement to light also becomes a vulnerability, as their powers are diminished in the presence of overwhelming darkness. This inherent weakness has taught the Hikarino tribe the importance of unity and inner strength, as they rely on their collective spirit and unwavering determination to face the challenges that arise.<br></br>
                                                <br></br>
                                                Within the Hikarino tribe, power structures are built upon wisdom, compassion, and the ability to guide others toward enlightenment. The leaders of the tribe, known as the Luminarchs, rise to power through a rigorous selection process that spans generations. Luminarchs are chosen based on their exceptional attunement to light, their wisdom, and their ability to inspire and guide their people.<br></br>
                                                <br></br>
                                                <center>
                                                    <div className="aspect-w-2 aspect-h-3 rounded-xl overflow-hidden sm:col-span-4 lg:col-span-5">
                                                        <img
                                                            src="/config/images/luminarch2.jpg"
                                                            className="object-center object-cover rounded-xl w-full h-full"
                                                        />
                                                    </div>
                                                </center>
                                                <br></br>
                                                The ascent to leadership is not solely based on raw power but rather on the development of one's spiritual and intellectual prowess. Prospective Luminarchs undergo rigorous training, mastering the intricacies of their attunement and cultivating a deep understanding of the natural world. They must demonstrate unwavering commitment to the principles of harmony and peace, as well as the ability to navigate the complexities of diplomatic relationships with other tribes.<br></br>
                                                <br></br>
                                                The current leader of the Hikarino tribe, the Luminarch Eiko, ascended to power through a combination of her prodigious attunement to light, her profound wisdom, and her exceptional leadership skills. Eiko had demonstrated remarkable capabilities from a young age, displaying an unmatched understanding of the delicate balance between light and darkness.<br></br>
                                                <br></br>
                                                Eiko's rise to power was not without obstacles. She faced internal divisions within the tribe, where some questioned her ability to lead due to her relative youth and lack of experience. However, through her unwavering commitment to the Hikarino tribe's core values, her diplomatic prowess, and her ability to inspire unity, Eiko triumphed over these challenges, solidifying her place as the Luminarch and guiding the Hikarino tribe toward a future of peace and enlightenment.<br></br>
                                                <br></br>
                                                As a tribe, the Hikarino people strive not only to defend their ideals but also to spread light and understanding throughout Maho. They seek to forge alliances and cultivate harmony with other tribes, recognizing that cooperation and mutual respect are essential in the face of darkness. Through their continuous pursuit of enlightenment and their dedication to the greater good, the Hikarino tribe remains a beacon of hope and inspiration in the world of Maho.<br></br>
                                                <center>
                                                    <div className="aspect-w-2 aspect-h-3 rounded-xl overflow-hidden sm:col-span-4 lg:col-span-5">
                                                        <img
                                                            src="/config/images/hikarino_gryphon.jpg"
                                                            className="object-center object-cover rounded-xl w-full h-full"
                                                        />
                                                    </div>
                                                </center>
                                            </p>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
