import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, PlusSmIcon, MinusSmIcon } from "@heroicons/react/outline";

import { Button } from '@nextui-org/react';
import { useNFT } from "../../hooks/useNFT";
import tokens from "../../allowlist.json";
import { ethers } from "ethers";

const product = {
    name: "Terms and Conditions",
    imageAlt: "",
};

export default function CommercialRightsModal() {
    const [open, setOpen] = useState(false);

    return (
        <>
            <button
                onClick={() => setOpen(true)}
                className="inline-block mt-3 text-center bg-gray-700 border border-transparent shadow-md rounded-full py-2 px-5 text-xl font-medium text-white hover:bg-gray-900"
            >Terms & Conditions</button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-10 inset-0 overflow-y-auto"
                    onClose={setOpen}
                >
                    <div
                        className="flex min-h-screen text-center md:block md:px-2 lg:px-4"
                        style={{ fontSize: 0 }}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="hidden fixed inset-0 bg-grey-500 bg-opacity-75 transition-opacity md:block" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden md:inline-block md:align-middle md:h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
                                <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-lg sm:px-6 sm:pt-8 md:p-6 lg:p-8 rounded-xl">
                                    <button
                                        type="button"
                                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>

                                    <div className="sm:col-span-8 lg:col-span-7">
                                        <h2 className="text-4xl font-medium text-gray-900 sm:pr-12">
                                            {product.name}
                                        </h2>
                                        <hr className="border-t border-gray-300 my-8" />
                                        <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:items-center lg:gap-x-8">
                                        </div>

                                        <section
                                            aria-labelledby="information-heading"
                                            className="mt-1 bt-1"
                                        >
                                            <p className="font-bold text-lg text-gray-500 overflow-y-auto">
                                            INTRODUCTION & AGREEMENT TO THESE TERMS & CONDITIONS: This document is the "Terms & Conditions" for the non-fungible token ("NFT") project that has posted this document within its NFTs and/or at the point where its NFTs are sold. The NFT project that has posted this document within its NFTs and/or at the point where they are sold is referred to in this document as the "Project". These Terms & Conditions are a legally binding agreement regarding the Project and your rights and obligations related to it. By making a purchase from the Project or interacting with its NFTs, you are agreeing to these Terms & Conditions. PLEASE READ THESE TERMS & CONDITIONS CAREFULLY BEFORE INTERACTING WITH THE PROJECT OR ITS NFTs. THE PROJECT'S CREATOR (AS DEFINED BELOW) IS ONLY WILLING TO MAKE THE PROJECT AND THE RIGHTS STATED IN THESE TERMS & CONDITIONS AVAILABLE TO YOU IF YOU ACCEPT ALL OF THESE TERMS & CONDITIONS. IF YOU DO NOT FULLY AGREE TO THESE TERMS & CONDITIONS, YOU ARE NOT PERMITTED TO ACCESS OR USE THE PROJECTS OR ITS NFTs.

DEFINITIONS OF CREATOR, ART, OWN, PURCHASED NFT, THIRD PARTY IP, PROJECT MATERIALS & SMART CONTRACT: For the purposes of these Terms & Conditions, the capitalized phrases in quotes that are defined in the rest of this paragraph will have the definitions given to them in this paragraph. “Art” means any art, design, and drawings that may be associated with an NFT that you Own. “Own” means a NFT that you have majority purchased or otherwise rightfully majority acquired from a legitimate source, where proof of that purchase is recorded on the relevant blockchain. “Purchased NFT” means an NFT from the Project that you Own. "Creator" means, collectively, the individuals, companies, ogranizations and/or other entities that first offered the Project NFTs for sale, minting, giveaway, or trading (for example, and as applicable, the artist(s) and/or software developer(s) who authored the Project NFTs and Smart Contracts and initially made the NFTs available for sale on an NFT marketplace). “Third Party IP” means any patent rights, copyrights, trade secrets, trademarks, know-how or any other intellectual property rights recognized in any country or jurisdiction in the world that are not owned by the Creator. "Project Materials" means all Art, designs, names, copyrights, trademarks, patents, systems, methods, information, computer code, software, services, “look and feel”, organization, compilation of the content, code, data, and all other elements of this Project. "Smart Contract" means the software commonly referred to as "smart contracts" that makes up part of the Project and runs on the applicable blockchain network.

BLOCKCHAIN NETWORK: Through the Smart Contracts, users may buy, sell own and transfer the Project's digital characters, collectibles, generative artwork, and/or other digital assets. To interact with the Project, you will need to have a web browser and a digital wallet that is compatible with the NFT standard on the Project's applicable blockchain network (for example, and as applicable, the Ethereum blockchain). All transactions regarding this Project are managed and confirmed via the appliacble blockchain network. The Creator does not own or control your web browser, your digital wallet, any blockchain network such as the Ethereum network, or any other third-party site, product, or service that you might access, visit, or use for the purpose of enabling you to perform a transaction. The Creator will not be responsible for the acts or omissions of any third parties, nor will they be responsible for any damage that you may suffer as a result of your transactions or any other interaction with any third parties.

OWNERSHIP: When you purchase an NFT from the Project, you own only the NFT, not any related Art or any other Project Materials. You can trade the NFT, sell it, or give it away. Ownership of the NFT is governed only by the Smart Contract and the applicable blockchain network (for example, and if applicable, the Ethereum Network). The Creator owns the Art. You acknowledge and agree that the Creator (or, as applicable, any parties they have licensed rights from and/or who they transfer their rights to) own all legal right, title and interest in and to all other elements of the Project (incluidng, but not limited to, its Art), and all intellectual property rights in the project and the Project Materials. You do not have any rights in the Project Materials except for those rights expressly stated in these Terms & Conditions. All Project Materials are the property of the Creator (and/or their licensors, licensees, and/or assignees, if and to the extent applicable). The Creator (and, if applicable, their licensees, licensors, and assignees) reserve all rights in and to the Project Materials that are not expressly granted to you in these Terms & Conditions.

LICENSE FOR NON-COMMERCIAL USE OF THE ART: For so long as you continue to Own the applicable NFT and fully comply with these Terms & Conditions, the Creator grants you a limited worldwide, non-exclusive, non-transferable, royalty-free license to use, copy, and display the Art of your Purchased NFT solely for the following purposes: (a) for your own personal, non-commercial use; (b) as part of a marketplace that permits the purchase and sale of your Purchased NFT, but only if the marketplace cryptographically verifies each NFT owner’s rights to display the Art for their Purchased NFT to ensure that only the actual owner of the NFT can display the Art; or (c) as part of a third party website or application that permits the inclusion, involvement, or participation of your Purchased NFT, but only if the website/application cryptographically verifies each NFT owner’s rights to display the Art for their Purchased NFT to ensure that only the actual owner of the NFT can display the Art, and only if the Art is no longer visible once the owner of the Purchased NFT leaves or logs out of the applicable website/application.

GENERAL RESTRICTIONS: You agree not to do any of the following things unless you make a request to the Creator and are granted specific written permission by them: (a) modify the Art in any way; (b) use the Art to advertise, market, or sell any product or service; (c) use the Art in connection with images, videos, or other forms of media that depict hatred, intolerance, violence, cruelty, or anything else that could reasonably be found to constitute hate speech or otherwise infringe upon the rights of others; (d) use the Art in movies, videos, or any other forms of media, unless and only to the degree that the use is expressly authorized elsewhere in these Terms & Conditions or solely for your own personal, non-commercial use; (e) sell, distribute for commercial gain or otherwise commercialize merchandise that includes, contains, or consists of the Art, unless and only to the degree as expressly authorized elsewhere in these Terms & Conditions; (f) attempt to trademark, copyright, or otherwise acquire additional intellectual property rights in or to the Art.

RESTRICTIONS ON THIRD PARTY IP: If the Art contains Third Party IP (for example, intellectual property from a celebrity, athlete, sports league, or company other than the Creator), you understand and agree with the following: (a) you will only have the rights to use the Third Party IP as it is included within your Purchased NFT and only so long as you are using your Purchased NFT in compliance with these Terms & Conditions; (b) any license for commercial use stated in these Terms & Conditions will not apply; (c) depending on the nature of the license granted from the owner of the Third Party IP, the project creator may need to pass through additional restrictions on your ability to use the Art; and (d) to the extent that the project creator informs you of these additional restrictions in writing (e.g., via email), you will be responsible for complying with all the additional restrictions from the date that you receive the notice, and failure to do so will be deemed a breach of these Terms & Conditions.

LICENSES END IF YOU NO LONGER OWN A PURCHASED NFT. For clarity, please note that the licenses granted in the clauses titled "LICENSE FOR NON-COMMERCIAL USE OF THE ART" and "LICENSE FOR NON-COMMERCIAL USE OF THE ART" apply only if you continue to Own the applicable NFT. If at any time you cease to Own (as defined in these Terms & Conditions) the Purcahsed NFT for any reason, these licenses will immediately expire with respect to you, and you will have no further rights to the Art for that Purchased NFT.

USE OF THE PROJECT'S NAME. Notwithstanding any contained in these Terms & Conditions, you are not granted any rights to use the project's name for any purpose without obtaining the Creator's prior written consent.

DISCLAIMERS. TO THE FULLEST EXTENT PERMISSIBLE PER APPLICABLE LAW, THE PROJECT CREATOR AND THEIR SUBSIDIARIES, AFFILIATES, AND LICENSORS, AND THEIR RESPECTIVE EMPLOYEES, AGENTS AND CONTRACTORS MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES (INCLUDING, WITHOUT LIMITATION, REGARDING ANY NFTS, SMART CONTRACT, ETC.), INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET. THE PROJECT CREATOR WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF ANY BLOCKCHAIN NETWORK (FOR EXAMPLE, THE ETHEREUM NETWORK) OR ANY DIGITAL AND/OR ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: USER ERROR, SUCH AS FORGOTTEN PASSWORDS OR INCORRECT SMART CONTRACTS OR OTHER TRANSACTIONS; SERVER FAILURE OR DATA LOSS; CORRUPTED WALLET FILES; OR UNAUTHORIZED ACCESS OR ACTIVITIES BY THIRD PARTIES, INCLUDING BUT NOT LIMITED TO THE USE OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK. NFTS ARE INTANGIBLE DIGITAL ASSETS THAT EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED ON THE BLOCKCHAIN. ALL SMART CONTRACTS ARE CONDUCTED AND OCCUR ON THE DECENTRALIZED LEDGER WITHIN THE BLOCKCHAIN, WHICH IS EARLY STAGE AND/OR EXPERIMENTAL TECHNOLOGY. THE PROJECT CREATOR MAKES NO GUARANTEES OR PROMISES WITH RESPECT TO SMART CONTRACTS. THE PROJECT CREATOR IS NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAINS OR ANY FEATURES OF OR RELATED TO THEM OR ANY ELECTRONIC AND/OR DIGITAL WALLET.

LIMITATION OF LIABILITY. THE PROJECT CREATOR AND THEIR SUBSIDIARIES, AFFILIATES, AND LICENSORS, AND THEIR RESPECTIVE EMPLOYEES, AGENTS AND CONTRACTORS, WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER INTANGIBLE LOSS, EVEN IF THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU AGREE THAT THEIR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS & CONDITIONS AND/OR ANY NFT, IS LIMITED TO THE AMOUNTS YOU ACTUALLY PAID THE PROJECT CREATOR UNDER THESE TERMS & CONDITIONS IN THE 12 MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE. THE PROJECT CREATOR SOLD THE PURCHASED NFT AND ENTERED INTO THESE TERMS & CONDITIONS IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN, WHICH REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK AND FORM AN ESSENTIAL BASIS OF THE BARGAIN. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, AND SOME JURISDICTIONS ALSO LIMIT DISCLAIMERS OR LIMITATIONS OF LIABILITY FOR PERSONAL INJURY FROM CONSUMER PRODUCTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO PERSONAL INJURY CLAIMS.

GOVERNING LAW. The Terms & Conditions shall be governed by and construed in accordance with the laws of the State of New York, excluding its conflicts-of-law rules, and the United States of America. For the purposes of these Terms & Conditions and/or any disputes related to them and/or the Purchased NFT, you and the Creator consent to exclusive jurisdiction and venue in the United States Federal Courts or state courts located in the Southern District of New York.

MISCELLANEOUS. These Terms & Conditions are the entire agreement between you and us regarding the Purchased NFT, and replace and trump all prior or contemporaneous communications and proposals (whether oral, written or electronic) between you and the Creator with respect to the Purchased NFT. If any provision of these Terms & Conditions is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary for the rest of the Terms & Conditions to remain enforceable. Failure to enforce any part of these Terms & Conditions shall not constitute a waiver of the right to later enforce that or any other part of these Terms & Conditions. For any waiver of compliance with these Terms & Conditions to be binding, a party's authorized representatives must provide written notice of that waiver. The language in these Terms & Conditions will be interpreted as to its fair meaning, and not strictly for or against any party. You may not assign any or your rights or obligations under these Terms & Conditions, whether by operation of law or otherwise, without our prior written consent. The Creator may assign their rights and obligations under these Terms & Conditions in their sole discretion
                                        </p>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
