import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, PlusSmIcon, MinusSmIcon } from "@heroicons/react/outline";

import { Button } from '@nextui-org/react';
import { useNFT } from "../../hooks/useNFT";
import tokens from "../../allowlist.json";
import { ethers } from "ethers";

const product = {
    name: "The Kaminari",
    price: .025,
    imageSrc: "/config/images/dragonriding.jpg",
    imageAlt: "",
};

export default function KaminariModal() {
    const [open, setOpen] = useState(false);

    return (
        <>
            <button
                onClick={() => setOpen(true)}
                className="inline-block mt-3 text-center bg-indigo-700 border border-transparent shadow-md rounded-full py-2 px-5 text-xl font-medium text-white hover:bg-indigo-900"
            >Backstory</button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-10 inset-0 overflow-y-auto"
                    onClose={setOpen}
                >
                    <div
                        className="flex min-h-screen text-center md:block md:px-2 lg:px-4"
                        style={{ fontSize: 0 }}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="hidden fixed inset-0 bg-grey-500 bg-opacity-75 transition-opacity md:block" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden md:inline-block md:align-middle md:h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
                                <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-lg sm:px-6 sm:pt-8 md:p-6 lg:p-8 rounded-xl">
                                    <button
                                        type="button"
                                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>

                                    <div className="sm:col-span-8 lg:col-span-7">
                                        <h2 className="text-4xl font-medium text-gray-900 sm:pr-12">
                                            {product.name}
                                        </h2>
                                        <hr className="border-t border-gray-300 my-8" />
                                        <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:items-center lg:gap-x-8">
                                            <div className="aspect-w-1 aspect-h-1 rounded-xl overflow-hidden sm:col-span-4 lg:col-span-12">
                                                <img
                                                    src="/config/images/stormbreaker_cliffs.jpg"
                                                    className="object-center object-cover rounded-xl w-full h-full"
                                                />
                                            </div>
                                        </div>

                                        <section
                                            aria-labelledby="information-heading"
                                            className="mt-1 bt-1"
                                        >
                                            <p className="font-bold text-lg text-gray-500 overflow-y-auto">
                                            The Kaminari tribe finds their home amidst the treacherous Stormbreak Cliffs, a coastal region where towering cliffs meet tumultuous seas. Dark storm clouds loom overhead, crackling with electricity, and lightning dances across the sky, illuminating the dramatic landscape. The Kaminari tribe draws strength and inspiration from the raw power of thunderstorms that rage along the cliffs.<br></br>
                                                <br></br>
                                                The Kaminari tribe possesses a unique attunement to electricity, enabling them to command lightning and harness the energy of thunderstorms. They are adept at summoning gusts of wind, invoking the power of thunder, and channeling electricity for various purposes. The Kaminari people have a deep connection to the elemental forces that govern the skies.<br></br>
                                                <br></br>
                                                Within the Kaminari tribe, the power of thunder resonates throughout their culture and shapes their collective spirit. They revere the elemental forces of nature, embracing the strength and intensity found within thunderstorms. The Kaminari people wield their attunement to electricity as a symbol of their power and a source of inspiration for their everyday lives.<br></br>
                                                <br></br>
                                                The tribe's strength lies in their ability to summon and control thunderstorms, channeling lightning with precision, and harnessing its energy for various purposes. They possess agility and swiftness akin to the lightning that courses through their veins. However, their reliance on thunderstorms for their powers means that their abilities may be diminished in calm weather conditions, requiring them to adapt their strategies accordingly.<br></br>
                                                <br></br>
                                                <center>
                                                    <div className="aspect-w-2 aspect-h-3 rounded-xl overflow-hidden sm:col-span-4 lg:col-span-5">
                                                        <img
                                                            src="/config/images/storm_king_reiko_2.jpg"
                                                            className="object-center object-cover rounded-xl w-full h-full"
                                                        />
                                                    </div>
                                                </center>
                                                <br></br>
                                                Power structures within the Kaminari tribe are built upon respect for strength, agility, and the ability to channel electricity effectively. The leaders of the tribe, known as Stormlords, rise to prominence based on their exceptional control over lightning, their strategic thinking, and their ability to navigate the ever-changing weather patterns of the Stormbreaker Cliffs.<br></br>
                                                <br></br>
                                                The ascent to leadership among the Kaminari tribe is marked by rigorous training and challenges that test an individual's attunement to electricity, their ability to interpret weather patterns, and their capacity for leadership. Those who demonstrate exceptional control over lightning, display wisdom in reading the signs of the storms, and earn the respect of their fellow tribe members ascend to become the Stormlords, guiding the tribe with their unwavering resolve.<br></br>
                                                <br></br>
                                                The current leader of the Kaminari tribe, the Stormlord Reiko, rose to power through a combination of natural talent and extensive training. Reiko exhibited an innate affinity for electricity from a young age, displaying an exceptional control over lightning and a deep understanding of the ever-shifting nature of storms. Through their mastery of thunder and lightning, their strategic brilliance, and the trust they inspired among their tribe, Reiko ascended to the position of Stormlord, leading the Kaminari tribe with a resounding roar.<br></br>
                                                <br></br>
                                                As a tribe, the Kaminari people embody the power and energy of thunderstorms. They seek to maintain a balance between the raw forces of nature and their own ability to wield them. The Kaminari tribe stands as a testament to the electrifying strength found within the skies, reminding the world of Maho of the untamed power that lies within the heart of a thunderstorm.<br></br>
                                                <br></br>

                                                <center>
                                                    <div className="aspect-w-2 aspect-h-3 rounded-xl overflow-hidden sm:col-span-4 lg:col-span-5">
                                                        <img
                                                            src="/config/images/stormbreaker_eagle.jpg"
                                                            className="object-center object-cover rounded-xl w-full h-full"
                                                        />
                                                    </div>
                                                </center>
                                            </p>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
